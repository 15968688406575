/**
 *  問答機器人視窗操作 
*/

import moment from "moment"

// 操控右下 button 開關
export const isOpened = ref(false)
export function toggleChatbot(onOff?: boolean|object) {
  if (typeof onOff === 'boolean') {
    isOpened.value = onOff
  } else {
    isOpened.value = !isOpened.value
  }
  console.log(isOpened.value)
}

// 操控 embed 版收合
export const isExpanded = ref(false)
export function toggleEmbedChat (onOff?: boolean|object) {
  if (typeof onOff === 'boolean') {
    isExpanded.value = onOff
  } else {
    isExpanded.value = !isExpanded.value
  }
}

// 紀錄 chatbot 使用日期
export const botUsedAt = useLocalStorage<string[]>('chatbot-usedAt', ref([]))
export function recordBotUsed() {
  const today = moment().format('YYYY-MM-DD')
  const isRecorded = botUsedAt.value.includes(today)
  if (!isRecorded) botUsedAt.value.push(today)
}
export function resetBotUsedAt() {
  botUsedAt.value = []
}

// 紀錄滿意度問卷：累計使用 3 次，發送一次問卷，且三個月內只發送一次
export const ratingShowedAt = useLocalStorage<string[]>('chatbot-rating-showedAt', ref([]))
export function recordRatingShowed() {
  // 發送問卷後，重新紀錄使用次數
  const today = moment().format('YYYY-MM-DD')
  ratingShowedAt.value.push(today)
  resetBotUsedAt()
}
export function allowOpenRating() {
  if (!ratingShowedAt.value.length) {
    return true
  } else {
    // 三個月內不再出現問卷
    const lastTime = ratingShowedAt.value.slice().pop()
    if (moment().subtract(3, 'months').isAfter(lastTime)) {
      return true
    } else {
      return false
    }
  }
}