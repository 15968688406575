import moment from "moment";
import { defineStore } from "pinia";

import { IMessage } from "api/chatbot/types";

const getDefaultMessages = (): Array<IMessage> => [
  {
    id: 0,
    publish_date: moment().format("YYYY-MM-DD HH:mm:ss"),
    type: "TEXT",
    from: "BOT",
    text: "您好，我是您的智能助理，歡迎說出您想要的服務！",
  },
];

const useChatStore = defineStore(
  "chat-store",
  () => {
    const data = ref<Array<IMessage>>(getDefaultMessages());

    const cleanup = () => {
      data.value = getDefaultMessages();
    };

    const insert = (newData: Array<IMessage> | IMessage) => {
      if (!data.value) {
        data.value = [];
      }

      if (Array.isArray(newData)) {
        data.value.push(...newData);
        return;
      }
      data.value.push(newData);
      return;
    };

    return {
      data,
      insert,
      cleanup,
    };
  },
  {
    share: {
      
    },
  }
);

export function useChatLog() {
  const authorization = useAuthorization();
  const chatStore = useChatStore();

  watch(authorization, () => {
    chatStore.cleanup();
  });

  return chatStore;
}
